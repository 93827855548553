import React from "react";
import { BlackCaptionText200 } from "../../text/Text";


type Props = {
  label: string
  element?: React.JSX.Element
  text?: string
}

const VehicleConnectionColumnBlock = ({ label, text, element }: Props) => {
  return (
    <div className={"w-30"}>
      <BlackCaptionText200>{label}</BlackCaptionText200>
      {text ?? element}
    </div>
  );
};

export default VehicleConnectionColumnBlock;