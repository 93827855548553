import { useCallback, useEffect } from "react";
import { Query, SortDirection } from "types/filter.types"
import { useUrlQuery } from "./router.hooks"

export const useFilterUrlQuery = <T extends Record<string, unknown>>() => {
    const { query, setQuery, resetQuery } = useUrlQuery<Query<T>>()

  const handleResetFilter = useCallback(
    () => {
      setQuery(q => {
        return {
          ...q,
          pagination: {
            page: "0",
            size: q.pagination?.size || "20"
          },
          filters: undefined
        };
      });
    },
    [setQuery]
  );

  const handleSetQuery = useCallback(
    (callback: (currentQuery: Query<T>) => T) => {
      setQuery(q => {
        const updatedFilters = callback(q);
        return {
          ...q,
          pagination: {
            page: "0",
            size: q.pagination?.size || "20"
          },
          filters: updatedFilters ? { ...q.filters, ...updatedFilters } : undefined
        };
      });
    },
    [setQuery]
  );


    const handleSetQueryWithoutResettingPagination = useCallback(
      (callback: (currentQuery: Query<T>) => T) => {
          setQuery(q => {
              const updatedFilters = callback(q);
              return {
                  ...q,
                  filters: updatedFilters ? { ...q.filters, ...updatedFilters } : undefined
              };
          });
      },
      [setQuery]
    );


    return { setFilterQuery: handleSetQuery,handleResetFilter, filterQuery: query.filters, resetQuery,handleSetQueryWithoutResettingPagination }
}



export const useSortingUrlQuery = <T extends Record<string, unknown>>() => {
    const { query, setQuery, resetQuery } = useUrlQuery<Query<T>>()
    const handleSetQuery = useCallback(
        (key: string, direction: SortDirection) => {
            setQuery(q => {
                return {
                    ...q,
                    pagination: undefined,
                    sort: {
                        [key]: direction
                    }
                };
            })
        },
        [setQuery],
    )

    return { setSortingQuery: handleSetQuery, sortingQuery: query.sort, resetQuery }
}


export const useSortFilterOptionsBySelectedItems = <T>(setter: (value: React.SetStateAction<T[]>) => void, checker: (item: T) => boolean) => {
    useEffect(() => {
        setter((options) =>
          options.sort((a, b) => {
              // Assuming isSelectedVehicle is a function that takes a vehicle and returns a boolean
              const isSelectedA = checker(a);
              const isSelectedB = checker(b);

              // Sort in descending order: true first, false last
              return Number(isSelectedB) - Number(isSelectedA);
          })
        );
    }, [checker, setter]);
};