import CancelButton from "components/button/CancelButton";
import SaveButton from "components/button/SaveButton";
import Col from "components/custom/Col";
import Row from "components/custom/Row";
import InputWithValidationControllerError from "components/form/InputWithValidationControllerError";
import { BlackBodyText100, BlackH5Text0, BlackSubtitleText200 } from "components/text/Text";
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Form, FormGroup, Modal, ModalBody } from "reactstrap";
import { updateOrganizationAsync } from "services/organization.services";
import { InputType } from "types/form.types";
import {
  OrganizationDetails,
  OrganizationFeeSettingsInvoiceCycle,
  OrganizationSettingsData
} from "types/organization.types";
import { getFloatRateByPercentString, getFormattedCentByUsdCreditString } from "utils/format.utils";
import { getOrganizationFeeSettingsByOrganizationDetails } from "utils/organization.utils";

type Props = {
  isModalOpen: boolean;
  onModalClosed: () => void;
  organizationDetails?: OrganizationDetails;
  refetchOrganizationDetails: () => Promise<void>;
};

type FormValues = {
  perVehicle?: string;
  perDriver?: string;
  organizationDiscountRate?: string;
  driverDiscountRate?: string;
  organizationServiceRate?: string;
  driverServiceRate?: string;
  chargingLateFeeRateForDriver?: string;
  invoiceCycle?: OrganizationFeeSettingsInvoiceCycle | undefined;
  netPaymentTerms: number;
  purchaseOrder?: string | null
};

function OrganizationFeeSettingsFormModal({
                                            isModalOpen,
                                            onModalClosed,
                                            organizationDetails,
                                            refetchOrganizationDetails
                                          }: Props) {
  const defaultSettingsValues = useMemo(
    () => getOrganizationFeeSettingsByOrganizationDetails(organizationDetails),
    [organizationDetails]
  );

  const { register, handleSubmit, control, errors, reset, setValue, trigger } = useForm<FormValues>({
    defaultValues: defaultSettingsValues,
    mode: "all"
  });

  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [invoiceCycle, setInvoiceCycle] = useState(defaultSettingsValues?.invoiceCycle);

  useEffect(() => {
    if (defaultSettingsValues) {
      setInvoiceCycle(defaultSettingsValues.invoiceCycle);
      reset(defaultSettingsValues);
    }
  }, [defaultSettingsValues, reset, setValue]);

  const updateOrganizationAsyncCallback: SubmitHandler<FormValues> = useCallback(
    async (validData) => {
      if (!organizationDetails) {
        return;
      }

      trigger();

      const { id, active, limits } = organizationDetails;

      const {
        driverDiscountRate,
        driverServiceRate,
        organizationDiscountRate,
        organizationServiceRate,
        perDriver,
        perVehicle,
        netPaymentTerms,
        purchaseOrder,
        chargingLateFeeRateForDriver
      } = validData;

      const settings: OrganizationSettingsData = {
        ...organizationDetails.settings.data,
        allowOverlappingDriverAssignments: organizationDetails.settings.data.allowOverlappingDriverAssignments,
        autoAssignDummyVehicle: organizationDetails.settings.data.autoAssignDummyVehicle,
        autoApproveSelfSignUps: organizationDetails.settings.data.autoApproveSelfSignUps,
        invoice: {
          netPaymentTerms,
          purchaseOrder: (purchaseOrder === "" || purchaseOrder === undefined) ? null : purchaseOrder
        },
        platformFees: {
          perVehicle: getFormattedCentByUsdCreditString(perVehicle),
          perDriver: getFormattedCentByUsdCreditString(perDriver)
        },
        chargingDiscountRates: {
          organization: getFloatRateByPercentString(organizationDiscountRate),
          driver: getFloatRateByPercentString(driverDiscountRate)
        },
        chargingServiceRates: {
          organization: getFloatRateByPercentString(organizationServiceRate),
          driver: getFloatRateByPercentString(driverServiceRate)
        },
        chargingLateFeeRates: {
          driver: getFloatRateByPercentString(chargingLateFeeRateForDriver)
        },
        chargingBillingCycle: invoiceCycle ?? OrganizationFeeSettingsInvoiceCycle.Monthly
      };

      try {
        setIsSubmitting(true);

        await updateOrganizationAsync(id, {
          credit: limits.chargingLimit,
          active,
          settings
        });

        refetchOrganizationDetails();
        onModalClosed();
      } catch (error: any) {
        setApiErrorMessage(error?.message);
        console.error(error);
      } finally {
        setIsSubmitting(false);
      }
    },
    [invoiceCycle, onModalClosed, organizationDetails, refetchOrganizationDetails, trigger]
  );

  /**
   *
   */
  const handleFormSubmit = useCallback(
    async (e: SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
      e.preventDefault();

      await trigger();

      handleSubmit(updateOrganizationAsyncCallback)(e);
    },
    [handleSubmit, updateOrganizationAsyncCallback, trigger]
  );

  return (
    <Modal isOpen={isModalOpen} toggle={onModalClosed} className="wide-xs modal-dialog-centered  overflow-hidden  ">
      <ModalBody className="w-md-100 h-md-75  h-lg-50  h-max-600px   max-w-90  overflow-auto  position-relative">
        <div className="p-1">
          <BlackH5Text0>Fee Settings</BlackH5Text0>
          <BlackBodyText100>{organizationDetails?.businessName} </BlackBodyText100>
          <Form className="w-100  mt-2" onReset={onModalClosed} onSubmit={handleFormSubmit}>
            <Col className="mt-4">
              <FormGroup className="mt-2">
                <InputWithValidationControllerError
                  register={register}
                  name="perVehicle"
                  inputLabel="Vehicle fee (per vehicle)"
                  control={control}
                  className="form-control"
                  placeholder="Enter the vehicle fee"
                  errors={errors}
                  inputType={InputType.NumberByDollar}
                />
              </FormGroup>
            </Col>
            <Col className="mt-4">
              <FormGroup className="mt-2">
                <InputWithValidationControllerError
                  register={register}
                  name="perDriver"
                  inputLabel="Driver fee (per driver)"
                  control={control}
                  className="form-control"
                  placeholder="Enter the driver fee"
                  errors={errors}
                  inputType={InputType.NumberByDollar}
                />
              </FormGroup>
            </Col>

            <Col className="mt-4">
              <FormGroup className="mt-2">
                <InputWithValidationControllerError
                  register={register}
                  name="organizationServiceRate"
                  inputLabel="Org. pays charging commission rate"
                  control={control}
                  className="form-control"
                  placeholder="Enter the commission rate"
                  errors={errors}
                  inputType={InputType.Percent}
                />
              </FormGroup>
            </Col>
            <Col className="mt-4">
              <FormGroup className="mt-2">
                <InputWithValidationControllerError
                  register={register}
                  name="driverServiceRate"
                  inputLabel="Driver pays charging commission rate"
                  control={control}
                  className="form-control"
                  placeholder="Enter the commission rate"
                  errors={errors}
                  inputType={InputType.Percent}
                />
              </FormGroup>
            </Col>
            <Col className="mt-4">
              <FormGroup className="mt-2">
                <InputWithValidationControllerError
                  register={register}
                  name="organizationDiscountRate"
                  inputLabel="Org. pays charging discount rate"
                  control={control}
                  className="form-control"
                  placeholder="Enter the discount rate"
                  errors={errors}
                  inputType={InputType.Percent}
                />
              </FormGroup>
            </Col>
            <Col className="mt-4">
              <FormGroup className="mt-2">
                <InputWithValidationControllerError
                  register={register}
                  name="driverDiscountRate"
                  inputLabel="Driver pays charging discount rate"
                  control={control}
                  className="form-control"
                  placeholder="Enter the discount rate"
                  errors={errors}
                  inputType={InputType.Percent}
                />
              </FormGroup>
            </Col>


            <Col className="mt-4">
              <FormGroup className="mt-2">
                <InputWithValidationControllerError
                  register={register}
                  name="netPaymentTerms"
                  inputLabel="Net payment terms"
                  control={control}
                  className="form-control"
                  placeholder="Enter the Net payment terms"
                  errors={errors}
                  inputType={InputType.NetPaymentTerm}
                />
              </FormGroup>
              <FormGroup className="mt-2">
                <InputWithValidationControllerError
                  register={register}
                  name="purchaseOrder"
                  inputLabel="Purchase order (PO)"
                  control={control}
                  className="form-control"
                  placeholder="Enter the Purchase order"
                  errors={errors}
                  inputType={InputType.PurchaseOrder}
                />
              </FormGroup>
            </Col>

            <Col className="mt-4">
              <BlackSubtitleText200>Invoice Cycle (Org. Charging Fee)</BlackSubtitleText200>

              <FormGroup
                name="invoiceCycle"
                className="custom-control custom-radio form-control  d-flex align-items-center  mt-2"
                htmlFor="weekly"
              >
                <input
                  type="radio"
                  className="custom-control-input h-100"
                  name="invoiceCycle"
                  id="weekly"
                  onChange={() => setInvoiceCycle(OrganizationFeeSettingsInvoiceCycle.Weekly)}
                  value={OrganizationFeeSettingsInvoiceCycle.Weekly}
                  checked={invoiceCycle === OrganizationFeeSettingsInvoiceCycle.Weekly}
                />
                <label className="custom-control-label w-100 " htmlFor="weekly">
                  Weekly
                </label>
              </FormGroup>

              <FormGroup
                className="custom-control custom-radio form-control d-flex align-items-center"
                htmlFor="monthly"
                name="invoiceCycle"
              >
                <input
                  type="radio"
                  className="custom-control-input"
                  name="invoiceCycle"
                  id="monthly"
                  onChange={() => setInvoiceCycle(OrganizationFeeSettingsInvoiceCycle.Monthly)}
                  value={OrganizationFeeSettingsInvoiceCycle.Monthly}
                  checked={invoiceCycle === OrganizationFeeSettingsInvoiceCycle.Monthly}
                />
                <label className="custom-control-label w-100" htmlFor="monthly">
                  Monthly
                </label>
              </FormGroup>
            </Col>

            <Col className="mt-4">
              <FormGroup className="mt-2">
                <InputWithValidationControllerError
                  register={register}
                  name="chargingLateFeeRateForDriver"
                  inputLabel="Driver charging late fee rate"
                  control={control}
                  className="form-control"
                  placeholder="Enter the rate"
                  errors={errors}
                  inputType={InputType.Percent}
                />
              </FormGroup>
            </Col>
            <Row className="w-100 d-flex justify-content-end align-items-center mt-4 pt-4">
              <CancelButton type="reset" />
              <div className="ml-1">
                <SaveButton isLoading={isSubmitting} />
              </div>
            </Row>
          </Form>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default OrganizationFeeSettingsFormModal;
