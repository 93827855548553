import Card from "components/card/Card";

import Col from "components/custom/Col";
import { OrganizationDetails } from "types/organization.types";
import OrganizationDetailsCardBlock from "./OrganizationDetailsCardBlock";
import OrganizationDetailsMultipleDriverAssignmentsStatusEditor
  from "./form/OrganizationDetailsMultipleDriverAssignmentsStatusEditor";
import OrganizationDetailsAutoSignupApprovalStatusEditor
  from "./form/OrganizationDetailsAutoSignupApprovalStatusEditor";
import OrganizationDetailsDummyVehicleAssignStatusEditor
  from "./form/OrganizationDetailsDummyVehicleAssignStatusEditor";
import OrganizationDetailsCardTitleBlock from "./OrganizationDetailsCardTitleBlock";
import HorizontalLine from "../line/HorizontalLine";

type Props = {
  readonly refetchOrganizationDetails: () => Promise<void>;
  readonly organizationDetails?: OrganizationDetails;
  readonly isLoading: boolean;
};

function OrganizationDetailsDriverSettingsCard({
                                                 organizationDetails,
                                                 isLoading,
                                                 refetchOrganizationDetails
                                               }: Props) {
  return (
    <Col className="h-235px  flex-column justify-between ">
      <Card>

        <OrganizationDetailsCardTitleBlock
          title={"Driver settings"}
        />
        <HorizontalLine />
        <OrganizationDetailsCardBlock
          paddingClass={"p-0"}
          isBorderBottomVisible={false}
          isLoading={isLoading}
          leftLabel="Allow multiple overlapping driver assignments to a vehicle"
          rightTextOverwrite={<OrganizationDetailsMultipleDriverAssignmentsStatusEditor
            refetchOrganizationDetails={refetchOrganizationDetails}
            organizationDetails={organizationDetails}
          />}
        />

        <HorizontalLine />
        <OrganizationDetailsCardTitleBlock
          title={"Driver Self signup"}
        />
        <OrganizationDetailsCardBlock
          paddingClass={"p-0"}
          isBorderBottomVisible={false}
          isLoading={isLoading}
          leftLabel="Auto approve driver self signup requests"
          rightTextOverwrite={<OrganizationDetailsAutoSignupApprovalStatusEditor
            refetchOrganizationDetails={refetchOrganizationDetails}
            organizationDetails={organizationDetails}
          />}
        />
        <HorizontalLine />
        <OrganizationDetailsCardBlock
          paddingClass={"p-0"}
          isBorderBottomVisible={false}
          isLoading={isLoading}
          leftLabel="Auto assign dummy vehicle to new drivers"
          rightTextOverwrite={<OrganizationDetailsDummyVehicleAssignStatusEditor
            refetchOrganizationDetails={refetchOrganizationDetails}
            organizationDetails={organizationDetails}
          />}
        />
      </Card>
    </Col>
  );
}

export default OrganizationDetailsDriverSettingsCard;
