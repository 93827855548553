import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import { AlertSliceState } from "types/alert.types";
import { OrganizationSliceState } from "types/organization.types";
import alertSlice from "./slices/alertSlice";
import organizationSlice from "./slices/organization-slice";
import fleetGroups from "./slices/fleetGroupsSlice";
import userSlice from "./slices/userSlice";
import { FleetGroupSliceState } from "../types/fleet-group.types";

export type RootState = {
  organizationSlice: OrganizationSliceState,
  alertSlice: AlertSliceState,
  fleetGroups: FleetGroupSliceState
  userSlice: any
};

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"]
};

const reducers = combineReducers<RootState>({
  organizationSlice,
  alertSlice,
  fleetGroups,
  userSlice
});


const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk]
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
