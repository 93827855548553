import { useGoogleApiLoader } from "./google-maps.hooks";
import { useCallback, useEffect, useState } from "react";
import { geocodeAddressAsync, geocodeLocationAsync } from "../services/location.services";
import { LatLng, LocationRectangleBounds, Polygon } from "../types/location.types";
import { calculatePolygonCenter, calculateZoomForRectangle, getRectangleBoundsAndCenter } from "../utils/polygon.utils";
import { useDebounce } from "./timer.hooks";

export const DefaultLocation = "California";

export const useMapWithRectangleBoundsHooks = (initialAddress?: string, polygon?: Polygon, isLoading?: boolean) => {
  const { isLoaded: isGoogleApiLoaded } = useGoogleApiLoader();

  const isLoaded = isGoogleApiLoaded && !isLoading;

  const [zoom, setZoom] = useState(20);
  const [center, setCenter] = useState<LatLng | undefined>();


  /*
  * I’m keeping the new bounds in a separate state on each drag because dragging the map triggers numerous update requests,
  * which would cause frequent, unnecessary re-renders if I updated the map’s main bounds state directly.
  * This separate state avoids excessive re-rendering and reduces buggy behavior.
  * */
  const [mapBounds, setMapBounds] = useState<LocationRectangleBounds | undefined>();
  const [boundsToSubmit, setBoundsToSubmit] = useState<LocationRectangleBounds | undefined>(mapBounds);

  const debouncedBounds = useDebounce(boundsToSubmit, 1000);

  const [readOnlyAddress, setReadOnlyAddress] = useState("");
  const [autoCompleteAddress, setAutoCompleteAddress] = useState("");

  /**
   * Init zoom by polygon bounds.
   */
  useEffect(() => {
    if (!isLoaded || !polygon) return;

    const boundsAndCenter = getRectangleBoundsAndCenter(polygon.coordinates);

    if (!boundsAndCenter?.bounds) {
      return;
    }
    setZoom(calculateZoomForRectangle(boundsAndCenter.bounds));
  }, [isLoaded, polygon]);


  const setCenterAndBoundsByLocation = useCallback(async (polygon: Polygon) => {
    const rectangleBoundsAndCenter = getRectangleBoundsAndCenter(polygon.coordinates);
    if (!rectangleBoundsAndCenter) {
      return;
    }

    const { center, bounds } = rectangleBoundsAndCenter;

    setCenter(center);
    setBoundsToSubmit(bounds);
    setMapBounds(bounds);
  }, []);

  const setCenterAndBoundsByAddress = useCallback(async (address: string) => {
    const radius = 0.00009;

    try {
      const geocoderResult = await geocodeAddressAsync(address);
      const location = geocoderResult.geometry.location;
      const center = { lat: location.lat(), lng: location.lng() };
      const bounds = {
        north: center.lat + radius,
        south: center.lat - radius,
        east: center.lng + radius,
        west: center.lng - radius
      };

      setCenter(center);
      setBoundsToSubmit(bounds);
      setMapBounds(bounds);

    } catch (error) {
      console.error("Failed to fetch coordinates by address:", error);
    }
  }, []);


  /**
   * Init bounds and center.
   */
  useEffect(() => {
    if (!isLoaded) return;

    if (polygon) {
      setCenterAndBoundsByLocation(polygon);
      return;
    }

    setCenterAndBoundsByAddress(initialAddress ?? DefaultLocation);
  }, [polygon, isLoaded, initialAddress, setCenterAndBoundsByLocation, setCenterAndBoundsByAddress]);

  /**
   * Update bounds and center according to address change.
   */
  useEffect(() => {
    if (autoCompleteAddress) {
      setCenterAndBoundsByAddress(autoCompleteAddress);
    }
  }, [setCenterAndBoundsByAddress, autoCompleteAddress]);

  const syncReadonlyAddressByBoundCenter = useCallback(async (lat: number, lng: number) => {
    try {
      const geocoderResult = await geocodeLocationAsync(lat, lng);
      const formattedAddress = geocoderResult.formatted_address;

      setReadOnlyAddress(formattedAddress);

      const input = document.getElementById("google-autocomplete-address") as HTMLInputElement;
      if (input) {
        input.value = formattedAddress;
      }

    } catch (error) {
      console.error("Failed to fetch coordinates by location:", error);
    }

  }, []);


  /**
   * Update readonly address value according to map bound center change.
   */
  useEffect(() => {
    if (!debouncedBounds) {
      return;
    }

    const rectangleCenter = calculatePolygonCenter([
      { lat: debouncedBounds.north, lng: debouncedBounds.east },
      { lat: debouncedBounds.north, lng: debouncedBounds.west },
      { lat: debouncedBounds.south, lng: debouncedBounds.east },
      { lat: debouncedBounds.south, lng: debouncedBounds.west }
    ]);

    syncReadonlyAddressByBoundCenter(rectangleCenter.lat, rectangleCenter.lng);
  }, [debouncedBounds, syncReadonlyAddressByBoundCenter]);


  /**
   * sync the mapBounds state with boundsToSubmit.
   */
  useEffect(() => {
    setBoundsToSubmit(mapBounds);
  }, [mapBounds]);


  return {
    mapBounds,
    setMapBounds,
    boundsToSubmit,
    setBoundsToSubmit,
    readOnlyAddress,
    autoCompleteAddress,
    setAutoCompleteAddress,
    center,
    zoom,
    isLoaded
  };
};