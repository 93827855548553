import UserAvatar from "components/avatar/UserAvatar";
import Card from "components/card/Card";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Col from "components/custom/Col";
import Row from "components/custom/Row";
import LoadingSkeleton from "components/loading/LoadingSkeleton";

import ClipboardWithCopyIcon from "components/clipboard/ClipboardWithCopyIcon";
import { BlackBodyText0, BlackH6Text100 } from "components/text/Text";
import { OrganizationDetails } from "types/organization.types";
import OrganizationDetailsCardBlock from "./OrganizationDetailsCardBlock";
import OrganizationDetailsStatusEditor from "./form/OrganizationDetailsStatusEditor";
import OutlinedButton from "../button/OutlinedButton";
import { useBranchIoDriverAppLink } from "../../hooks/driver.hooks";
import { useAlertManager } from "../../hooks/alert.hooks";
import ReferralCodeIcon from "../../assets/icons/ReferralCodeIcon";

type Props = {
  readonly refetchOrganizationDetails: () => Promise<void>;
  readonly organizationDetails?: OrganizationDetails;
  readonly isLoading: boolean;
};

function OrganizationDetailsPersonalInformationCard({
                                                      organizationDetails,
                                                      isLoading,
                                                      refetchOrganizationDetails
                                                    }: Props) {

  const { handleOpenSuccessAlert } = useAlertManager();

  const fleetCode =organizationDetails?.signUpCode?.code
  const {appLink} = useBranchIoDriverAppLink(fleetCode)


  return (
    <Col className="h-235px  flex-column justify-between ">
      <Card>
        <Row className="mb-2  align-items-center">
          {isLoading ? (
            <LoadingSkeleton count={2} />
          ) : (
            <>
              {" "}
              <UserAvatar userName={organizationDetails?.businessName} />{" "}
              <BlackH6Text100 className="ml-2">{organizationDetails?.businessName}</BlackH6Text100>
            </>
          )}
        </Row>

        <OrganizationDetailsCardBlock
          isLoading={isLoading}
          leftLabel="ID"
          leftTextOverwrite={<ClipboardWithCopyIcon TextComponent={BlackBodyText0} text={organizationDetails?.id} />}
          rightLabel="Status"
          rightTextOverwrite={
            <OrganizationDetailsStatusEditor
              refetchOrganizationDetails={refetchOrganizationDetails}
              organizationDetails={organizationDetails}
            />
          }
          rightText={organizationDetails?.status}
        />

        <OrganizationDetailsCardBlock
          isLoading={isLoading}
          leftLabel="Officer name"
          leftText={organizationDetails?.name ?? "-"}
        />

        <OrganizationDetailsCardBlock
          isLoading={isLoading}
          leftLabel="Phone number"
          leftTextOverwrite={
            <ClipboardWithCopyIcon TextComponent={BlackBodyText0} text={organizationDetails?.phoneNumber} />
          }
          rightLabel="Email"
          rightTextOverwrite={
            <ClipboardWithCopyIcon TextComponent={BlackBodyText0} text={organizationDetails?.email} />
          }
        />


        <OrganizationDetailsCardBlock
          isBorderBottomVisible={false}
          isLoading={isLoading}
          leftLabel="Fleet code"
          leftTextOverwrite={
            <ClipboardWithCopyIcon TextComponent={BlackBodyText0} text={fleetCode} />
          }

          rightTextOverwrite={
            <CopyToClipboard
              disabled={!appLink}
              text={`Driver app: ${appLink}, Unique fleet code: ${fleetCode}`}
              onCopy={() => handleOpenSuccessAlert("Copied to clipboard!")}
            >
              <OutlinedButton
                className="ml-2"
                isDisabled={!appLink}
                height={36}
                text="Copy code & link"
                leftIcon={<ReferralCodeIcon className="mr-2" />}
              />
            </CopyToClipboard>
          }
        />

      </Card>
    </Col>
  );
}

export default OrganizationDetailsPersonalInformationCard;
