import { parsePhoneNumber } from "libphonenumber-js";
import { Address } from "../types/driver.types";


export function formatNumber(number) {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(number);
}

export function getFormattedNumberForEnergy(energy) {
  if (!energy) {
    return `0.00`;
  }

  return formatNumber(energy);
}

export function getMilesByKm(km: number) {
  if (!km) {
    return 0;
  }

  return km * 0.621371192;
}


export function formatMilesByKmForEnUs(km: number | string) {
  if (!km) {
    return 0;
  }
  const numberInKm = Number(km);

  return formatNumber(getMilesByKm(numberInKm));
}


export function getFormattedNumberForUsdByCent(cent: number | undefined | null) {
  return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format((cent ?? 0) / 100);
}


export const getFormattedCreditValueAsMultipliedInteger = (credit: string) => {
  let formattedCreditValue;
  formattedCreditValue = credit?.replace("$", "");
  formattedCreditValue = parseInt(formattedCreditValue?.replace(/,/g, "")?.split(".")[0]);
  return formattedCreditValue * 100;
};

export const getFormattedCreditValueWithoutDollarSignPrefix = (credit?: string) => {
  if (!credit) {
    return null;
  }
  const formattedCreditValue = getFormattedCreditValueAsMultipliedInteger(credit);

  const creditValueAsPayload = isNaN(formattedCreditValue) ? null : formattedCreditValue;
  return creditValueAsPayload;
};


export const getFormattedCostByProvider = (cent: number | undefined | null, provider: string) => {
  const isUnknownProvider = (provider === "home" || provider === "other");
  if (isUnknownProvider && typeof cent !== "number") {
    // if cent is not a number return N/A
    return "N/A";
  }

  return getFormattedNumberForUsdByCent(cent);
};


export const getFormattedPhoneNumber = (phoneNumber?: string) => {
  if (!phoneNumber) {
    return "";
  }

  const parsedPhoneNumber = parsePhoneNumber(phoneNumber);


  return parsedPhoneNumber.formatNational();
};

export const formatPhoneNumberByDialCodeAndNumber = (dialCode: {
  label: string,
  value: string
}, phoneNumber: string) => {
  return dialCode?.value + phoneNumber;
};


export const getFloatRateByPercentString = (percent?: string) => {
  if (!percent) {
    return 0;
  }

  return parseInt(percent.replace("%", ""));
};

export const getPercentStringByRate = (percent?: number) => {
  if (!percent) {
    return "0%";
  }
  return percent + "%";
};


export const getFormattedCentByUsdCreditString = (credit?: string) => {
  if (!credit) {
    return 0;
  }

  let formattedCreditValue = credit.replace("$", "").replace(/,/g, "");

  let [dollars, cents = "00"] = formattedCreditValue.split(".");

  let totalCents = parseInt(dollars) * 100;

  if (cents) {
    totalCents += parseInt(cents.length > 1 ? cents.substring(0, 2) : cents + "0");
  }

  return totalCents;
};




export const mapStringifiedBooleansAsBooleans = (stringifiedBooleanList?: string[]) => {
  return stringifiedBooleanList?.map(sb => sb === "true") ?? [];
};

export const stringifyAddress = (address?: Address) => {
  // "1901 Thornridge Cir. Shiloh, Hawaii 81063, US"
  if (!address) {
    return "-";
  }

  const { street, street2, city, state, postalCode, country } = address;

  const streetInfo = `${street ?? ""}${street2 ? " " + street2 : ""}`;
  return `${streetInfo}.  ${city ?? ""},  ${state ?? ""},  ${postalCode ?? ""}  ${country ?? ""}.`;
};