import React, { useMemo } from "react";
import { Vehicle, VehicleType } from "../../../types/vehicle.types";
import Row from "../../custom/Row";
import VehicleConnectionStatusText from "./VehicleConnectionStatusText";
import { getLocalFormattedDateForTables } from "../../../utils/date.utils";
import VehicleConnectionColumnBlock from "./VehicleConnectionColumnBlock";


type Props = {
  vehicle?: Vehicle
  type: VehicleType
}
const VehicleConnectionBlock = ({ vehicle, type }: Props) => {
  const vehicleStateDetails = useMemo(() => {
    switch (type) {
      case VehicleType.GEOTAB:
        return {
          name: "Geotab",
          connectionState: vehicle?.geotabConnectionState,
          connectedAt: vehicle?.geotabConnectedAt,
          disconnectedAt: vehicle?.geotabDisconnectedAt
        };
      case VehicleType.SAMSARA:
        return {
          name: "Samsara",
          connectionState: vehicle?.samsaraConnectionState,
          connectedAt: vehicle?.samsaraConnectedAt,
          disconnectedAt: vehicle?.samsaraDisconnectedAt
        };
      case VehicleType.SMARTCAR:
        return {
          name: "Smartcar",
          connectionState: vehicle?.smartcarConnectionState,
          connectedAt: vehicle?.smartcarConnectedAt,
          disconnectedAt: vehicle?.smartcarDisconnectedAt
        };
      case VehicleType.TESLA:
        return {
          name: "Tesla",
          connectionState: vehicle?.teslaConnectionState,
          connectedAt: vehicle?.teslaConnectedAt,
          disconnectedAt: vehicle?.teslaDisconnectedAt
        };
      default:
        return {
          connectionState: undefined,
          connectedAt: undefined,
          disconnectedAt: undefined
        };
    }
  }, [type, vehicle]);

  const { name, connectionState, connectedAt, disconnectedAt } = vehicleStateDetails;

  return (
    <Row className="align-items-center justify-between">
      <VehicleConnectionColumnBlock
        label={`${name} State`}
        element={<VehicleConnectionStatusText connectionStatus={connectionState} />}
      />
      <VehicleConnectionColumnBlock
        label={"Connected At"}
        text={getLocalFormattedDateForTables(connectedAt)}
      />
      < VehicleConnectionColumnBlock
        label={"Disconnect At"}
        text={getLocalFormattedDateForTables(disconnectedAt)}
      />
    </Row>
  );
};

export default VehicleConnectionBlock;