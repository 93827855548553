import React from "react";
import { NeutralOverlineText200, NeutralTitleSmallTextBlack } from "../text/Text";

type Props = {
  readonly title: string,
  readonly description?: string
};

function OrganizationDetailsCardTitleBlock({ title, description }: Props) {
  return <>
    <NeutralTitleSmallTextBlack>{title}</NeutralTitleSmallTextBlack>
    {description && (
      <NeutralOverlineText200>
        {description}
      </NeutralOverlineText200>
    )}
  </>;
}

export default OrganizationDetailsCardTitleBlock;
