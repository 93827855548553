import React from "react";

import { DriverHooks } from "../../types/driver.types";
import DriverDetailPageStatusCard from "./DriverDetailsPageStatusCard";
import DriverDetailsPagePersonalInformationCard from "./DriverDetailsPagePersonalInformationCard";
import DriverDetailsPageAddressCard from "./DriverDetailsPageAddressCard";
import DriverDetailsPageChargingCard from "./DriverDetailsPageChargingCard";
import Row from "../custom/Row";
import Col from "../custom/Col";
import DriverDetailsPageDepositCard from "../organization/driver/DriverDetailsPageDepositCard";

type Props = {
  driverHooks: DriverHooks
}

const DriverDetailsAboutSection = ({ driverHooks }: Props) => {
  return (
    <Row>
      <Col lg="6" className="pr-lg-2">
        <DriverDetailPageStatusCard {...driverHooks} />

        <div className="mt-4">
          <DriverDetailsPageDepositCard {...driverHooks} />
        </div>

        <div className="mt-4">
          <DriverDetailsPagePersonalInformationCard {...driverHooks} />
        </div>
      </Col>
      <Col lg="6" className="pl-lg-2 mt-4 mt-lg-0">
        <DriverDetailsPageAddressCard {...driverHooks} />
        <div className="mt-4">
          <DriverDetailsPageChargingCard {...driverHooks} />
        </div>
      </Col>
    </Row>
  );
};

export default DriverDetailsAboutSection;