import { useEffect, useState } from "react";
import { Address, DriverHooks } from "../types/driver.types";
import { PolygonLocation } from "../types/location.types";
import { useMapWithRectangleBoundsHooks } from "./map.hooks";
import { stringifyAddress } from "../utils/format.utils";
import branchIO from "branch-sdk";
import { getDriverReimbursementLocationFromLocations } from "../utils/driver.utils";

export const useDriverFormsReimbursementStepStates = (address?: Address, location?: PolygonLocation, isLoading?: boolean, isReimbursementActive_?: boolean) => {
  const [isReimbursementActive, setIsReimbursementActive] = useState<boolean | undefined>(isReimbursementActive_);

  useEffect(() => {
    setIsReimbursementActive(isReimbursementActive_);
  }, [isReimbursementActive_]);


  const mapWithRectangleBoundsHooks = useMapWithRectangleBoundsHooks(stringifyAddress(address), location?.polygon, isLoading);
  return {
    isReimbursementActive,
    setIsReimbursementActive,
    ...mapWithRectangleBoundsHooks
  };
};

export const useDriverDetailsReimbursementStates = (driverHook: DriverHooks) => {
  const { driver, isLoading } = driverHook;
  const chargingReimbursement = driver?.settings?.data.chargingReimbursement;
  const isReimbursementActive = chargingReimbursement?.enabled;

  return {
    ...useDriverFormsReimbursementStepStates(driver?.address, getDriverReimbursementLocationFromLocations(driverHook), isLoading, isReimbursementActive)
  };
};


const branchIoPublicKey = process.env.REACT_APP_BRANCHIO_PUBLIC_KEY;
export const useBranchIoDriverAppLink = (code?: string) => {
  const [appLink, setAppLink] = useState<string | null>("");

  useEffect(() => {
    if (!code || !branchIoPublicKey) {
      return;
    }
    branchIO.init(branchIoPublicKey)
    branchIO.link(
      {
        data: {
          fleetCode: code,
          "$canonical_url": "https://fleets.thebluedot.co/drivers"
        }
      },
      (err, link) => {
        if (err) {
          console.error(err);
          return;
        }
        setAppLink(link);
      }
    );
  }, [code]);

  return { appLink };
};