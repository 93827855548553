import Card from "components/card/Card";
import HorizontalLine from "components/line/HorizontalLine";
import LoadingSkeleton from "components/loading/LoadingSkeleton";
import { BlackBody2Text100, BlackOverlineText300, BlackSubtitleText100, BodyText } from "components/text/Text";
import { Driver } from "types/driver.types";
import Row from "../custom/Row";
import CreditCardIcon from "../../assets/icons/CreditCardIcon";


type Props = {
  isLoading: boolean;
  driver?: Driver;
};

export default function DriverDetailPageStatusCard({ driver, isLoading }: Props) {
  const isDriverActive = driver?.active;

  return (
    <>

      <Card padding="p-2 p-md-4">
        {isLoading ? (
          <LoadingSkeleton count={3} height={40} />
        ) : (
          <>
            <Row className="justify-between">
              <Row className="center">
                <BlackSubtitleText100>Status</BlackSubtitleText100>
              </Row>

              <Row className="align-items-center  clickable">
                <BodyText className={`${isDriverActive ? "text-green-200" : "text-danger"} mr-1`}>
                  {isDriverActive ? "Active" : "Inactive"}
                </BodyText>
              </Row>
            </Row>

            <HorizontalLine />

            <Row className="justify-between align-items-center">
              <Row className="center">
                <CreditCardIcon />
                <BlackSubtitleText100 className="ml-1">Card status</BlackSubtitleText100>
              </Row>

              <BlackBody2Text100>{driver?.hasDefaultPaymentMethod ? "Added" : "Not added"}</BlackBody2Text100>
            </Row>

            <BlackOverlineText300 className="mt-1">
              Displays whether the driver has added their payment method.
            </BlackOverlineText300>
          </>
        )}
      </Card>
    </>
  );
}

/**
 *
 */
