import React from "react";
import { useDriverDetailsReimbursementStates } from "../../hooks/driver.hooks";
import Card from "../card/Card";
import Col from "../custom/Col";
import { BlackBodyText100, BlackCaptionText0, BlackOverlineText300 } from "../text/Text";
import HorizontalLine from "../line/HorizontalLine";
import Row from "../custom/Row";
import ReadonlyReimbursementMap from "../reimbursement/ReadonlyReimbursementMap";
import { getDriverDetailsReimbursementInfo } from "../../utils/driver.utils";
import LoadingSkeleton from "../loading/LoadingSkeleton";
import { DriverHooks } from "../../types/driver.types";
import DetailsCardBlock from "../card/DetailsCard";
import { getFormattedNumberForUsdByCent } from "../../utils/format.utils";


type Props = {
  driverHooks: DriverHooks
}

const DriverDetailsReimbursementContent = ({ driverHooks }: Props) => {
  const readonlyReimbursementStates = useDriverDetailsReimbursementStates(driverHooks);

  const { isLoading, driver } = driverHooks;

  const {
    isReimbursementActive, fixedRate, rectangleBoundsAndCenter
  } = getDriverDetailsReimbursementInfo(driverHooks);


  const isEnabled =driver?.settings.data.chargingReimbursement.enabled

  return (
    <>
      <div>
        <Col md={"8"}>
          <Card>
            {isLoading ? <LoadingSkeleton count={4} /> : <>
              <DetailsCardBlock
                isBorderBottomVisible={false}
                isLoading={isLoading}
                leftLabel="Reimbursement status"
                leftText={isEnabled ? "Active" : "Inactive"}
              />

              {isReimbursementActive && (
                <>
                  <HorizontalLine />

                  <Row className="justify-between mb-2">
                    <Col>
                      <BlackBodyText100>Fixed rate</BlackBodyText100>
                      <BlackOverlineText300>This rate will remain the same until you change it</BlackOverlineText300>
                    </Col>


                    <Row className="align-items-center">
                      <BlackCaptionText0>{getFormattedNumberForUsdByCent(fixedRate)}/kWh</BlackCaptionText0>
                    </Row>
                  </Row>
                </>
              )}
            </>}
          </Card>

          {isReimbursementActive && rectangleBoundsAndCenter && (
            <Card className={"mt-4"}>
              {isLoading ? <LoadingSkeleton count={4} /> :
                <ReadonlyReimbursementMap
                  {...readonlyReimbursementStates}
                  isReimbursementActive={isReimbursementActive}
                />}
            </Card>
          )}
        </Col>
      </div>
    </>
  );
};

export default DriverDetailsReimbursementContent;