import { Driver, DriverHooks } from "types/driver.types";
import { PayerType } from "types/payment.types";
import { PolygonLocation } from "../types/location.types";
import { getRectangleBoundsAndCenter } from "./polygon.utils";
import { getFormattedNumberForUsdByCent } from "./format.utils";

export const getDriverActivationStatusColorAndLabel = (isActive) => {
  if (isActive) {
    return { color: "#2BA318", label: "Active" };
  }

  return { color: "#C4003E", label: "Inactive" };
};

export const getDriverStatusLabel = (active?: boolean) => {
  return active ? "Active" : "Inactive";
};

export const getDriverFullName = (driver?: Driver) => {
  const firstName = driver?.firstName ?? "";
  const lastName = driver?.lastName ?? "";

  return `${firstName} ${lastName}`;
};


export const getPayerLabelByType = (payerType: PayerType) => {
  switch (payerType) {
    case PayerType.ORGANIZATION:
      return "Organization";
    case PayerType.DRIVER:
      return "Driver";
    default:
      console.error("Unknown payer type!");
      return "Driver";
  }
};

export const getUserStatusAsBoolean = (status: string) => {
  return status === "Active";
};

export const getFullNameByFirstNameAndLastName = (firstName: string = "", lastName: string = "") => {
  return `${firstName} ${lastName}`;
};


export const getDriverPaymentResponsibilityLabelByPayerType = (payerType?: PayerType) => {
  if (!payerType) {
    return "-";
  }
  return getWhoPaysDisplayTextByPayerType(payerType) + " Pays";
};


export const getWhoPaysDisplayTextByPayerType = (payer: PayerType) => {
  switch (payer) {
    case PayerType.DRIVER:
      return "Driver";
    case PayerType.ORGANIZATION:
      return "Organization";
    default:
      return "-";
  }
};

export const getChargingLimit = (chargingLimit?: number) => {
  if (chargingLimit || chargingLimit === 0) {
    const formattedChargingLimit = getFormattedNumberForUsdByCent(chargingLimit);
    return { value: formattedChargingLimit, label: formattedChargingLimit };
  }

  return { value: null, label: "Not set" };
};


export const getDriverReimbursementLocationFromLocations = (driverHook: DriverHooks): PolygonLocation | undefined => {
  const { isLoading, driver } = driverHook;
  if (isLoading || !driver || !driver?.chargingReimbursementLocations) {
    return;
  }

  if (driver?.chargingReimbursementLocations.length > 0) {
    return driver.chargingReimbursementLocations[0];
  }
};


export const getDriverNameInitials = (driver?: Driver) => {
  const firstName = driver?.firstName ?? "";
  const lastName = driver?.lastName ?? "";

  return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
};

export const getDriverDetailsReimbursementInfo = (driverHook: DriverHooks) => {
  const { driver } = driverHook;

  const reimbursementLocations = getDriverReimbursementLocationFromLocations(driverHook);
  const chargingReimbursement = driver?.settings?.data.chargingReimbursement;
  const isReimbursementActive = chargingReimbursement?.enabled;
  const fixedRate = chargingReimbursement?.tariff?.fixed.energyPrice;

  const rectangleBoundsAndCenter = getRectangleBoundsAndCenter(reimbursementLocations?.polygon?.coordinates);

  return {
    driver, isReimbursementActive, fixedRate, rectangleBoundsAndCenter
  };
};