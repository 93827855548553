import DriverDetailsSectionFormBox from "./DriverDetailsSectionFormBox";
import DriverDetailsSectionCard from "./DriverDetailsSectionCard";
import DynamicFleetGroupBadgeList from "../list/DynamicFleetGroupBadgeList";
import { Driver } from "types/driver.types";
import { getDriverFullName } from "utils/driver.utils";
import { getFormattedPhoneNumber } from "utils/format.utils";
import { useFleetGroupBadgeDeeds } from "../../hooks/fleet-group.hooks";
import { TextButtonMode } from "../button/TextButton";
import UnderlinedTextButton from "../button/UnderlinedTextButton";
import React from "react";
import ArrowUpRightIcon from "assets/icons/ArrowUpRightIcon";

type Props = {
  isLoading: boolean;
  driver?: Driver;
};


export default function DriverDetailsPagePersonalInformationCard({ isLoading, driver }: Props) {
  /**
   * Hooks
   */
  const fleetGroups = driver?.groups ?? [];
  const { getGroupBadgeContent } = useFleetGroupBadgeDeeds(fleetGroups);

  /**
   * Effects
   */

  /**
   * Values for rendering
   */
  const driverEmail = driver?.email;
  const driverFullName = getDriverFullName(driver);
  const phoneNumber = getFormattedPhoneNumber(driver?.phoneNumber);

  return (
    <>
      <DriverDetailsSectionCard
        loadingCount={5}
        isLoading={isLoading}
        headerTitle={"Personal information"}
        isEditButtonShowing
        content={
          <>
            <DriverDetailsSectionFormBox label={"Full Name"} value={driverFullName} />
            <DriverDetailsSectionFormBox label={"Email"}>
              <UnderlinedTextButton
                onClick={() => window.open("mailto:" + driverEmail)}
                mode={TextButtonMode.BLUE}
                icon={<ArrowUpRightIcon />}
                text={driverEmail}
                className="d-flex align-items-center clickable"
              />

            </DriverDetailsSectionFormBox>
            <DriverDetailsSectionFormBox label={"Phone Number"} value={phoneNumber} />
            <DriverDetailsSectionFormBox isBorderHidden label={"Fleet Group (Optional)"}>
              <DynamicFleetGroupBadgeList getGroupBadgeContent={getGroupBadgeContent} fleetGroups={fleetGroups} />
            </DriverDetailsSectionFormBox>
          </>
        }
      />
    </>
  );
}
