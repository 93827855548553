import { useCallback, useState } from "react";
import { updateOrganizationAsync } from "services/organization.services";
import { OrganizationDetails } from "types/organization.types";
import EditorModal from "../../modal/EditorModal";
import { useForm } from "react-hook-form";
import { getFormattedCentByUsdCreditString } from "../../../utils/format.utils";
import { FormGroup } from "reactstrap";
import InputWithValidationControllerError from "../../form/InputWithValidationControllerError";
import { InputType } from "../../../types/form.types";
import { getOrganizationFeeSettingsByOrganizationDetails } from "../../../utils/organization.utils";
import { NeutralTitleMediumText100 } from "../../text/Text";
import Row from "../../custom/Row";
import OrangeLinkButton from "../../button/OrangeLinkButton";

type FormData = {
  amount?: string
}

type Props = {
  readonly organizationDetails?: OrganizationDetails;
  readonly refetchOrganizationDetails: () => Promise<void>;
};

function OrganizationDetailsDepositEditor({ organizationDetails, refetchOrganizationDetails }: Props) {

  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    depositAmount
  } = getOrganizationFeeSettingsByOrganizationDetails(organizationDetails);

  const { register, errors, control, handleSubmit } = useForm<FormData>({
    mode: "all",
    defaultValues: {
      amount: depositAmount
    }
  });

  const handleSubmitDepositAsync = useCallback(async (formData: FormData) => {
    if (!organizationDetails) {
      return;
    }

    const { id, limits, active, settings } = organizationDetails;

    try {
      setIsLoading(true);
      await updateOrganizationAsync(id, {
        active: active,
        credit: limits.chargingLimit,
        settings: {
          ...settings.data,
          user: {
            deposit: {
              amount: getFormattedCentByUsdCreditString(formData.amount)
            }
          }
        }
      });

      await refetchOrganizationDetails();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [organizationDetails, refetchOrganizationDetails]);


  return (
    <>
      <EditorModal
        modalSize="wide-xs"
        setIsModalOpen={setIsEditorOpen}
        isModalOpen={isEditorOpen}
        onSubmit={handleSubmit(handleSubmitDepositAsync)}
        isLoading={isLoading}
      >
        <FormGroup className="mt-2">
          <InputWithValidationControllerError
            register={register}
            name="amount"
            inputLabel="User deposit amount"
            control={control}
            className="form-control"
            placeholder="Enter amount"
            errors={errors}
            inputType={InputType.UserDepositAmount}
          />
        </FormGroup>
      </EditorModal>
      <Row>
        <NeutralTitleMediumText100>
          {depositAmount}
        </NeutralTitleMediumText100>
        <OrangeLinkButton
          onClick={() => setIsEditorOpen(true)}
          className="ml-1"
          text="Edit"
        />
      </Row>
    </>
  );
}

export default OrganizationDetailsDepositEditor;
