import BlackButton from "components/button/BlackButton";
import Card from "components/card/Card";

import Col from "components/custom/Col";

import { useState } from "react";
import { OrganizationDetails } from "types/organization.types";
import { getOrganizationFeeSettingsByOrganizationDetails } from "utils/organization.utils";
import OrganizationDetailsCardBlock from "./OrganizationDetailsCardBlock";
import OrganizationFeeSettingsFormModal from "./form/OrganizationFeeSettingsFormModal";
import OrganizationDetailsCardTitleBlock from "./OrganizationDetailsCardTitleBlock";
import HorizontalLine from "../line/HorizontalLine";

type Props = {
  readonly organizationDetails?: OrganizationDetails;
  readonly isLoading: boolean;
  readonly refetchOrganizationDetails: () => Promise<void>;
};

function OrganizationDetailsFeeSettingsCard({ organizationDetails, isLoading, refetchOrganizationDetails }: Props) {
  const [isSettingFormOpen, setIsSettingFormOpen] = useState(false);

  const handleCloseSettingsForm = () => setIsSettingFormOpen(false);
  const handleOpenSettingsForm = () => setIsSettingFormOpen(true);


  const {
    driverDiscountRate,
    driverServiceRate,
    perDriver,
    perVehicle,
    invoiceCycle,
    organizationDiscountRate,
    organizationServiceRate,
    netPaymentTerms,
    purchaseOrder,
    chargingLateFeeRateForDriver,
    depositAmount
  } = getOrganizationFeeSettingsByOrganizationDetails(organizationDetails);

  return (
    <>
      <OrganizationFeeSettingsFormModal
        organizationDetails={organizationDetails}
        isModalOpen={isSettingFormOpen}
        onModalClosed={handleCloseSettingsForm}
        refetchOrganizationDetails={refetchOrganizationDetails}
      />
      <Col className="h-235px  flex-column justify-between ">
        <Card>

          <OrganizationDetailsCardTitleBlock
            title={"Fee & Rate settings"}
            description={"It displays the fee values for the organization. Changes can be made by clicking on the settings."}
          />

          <HorizontalLine />

          <OrganizationDetailsCardBlock
            paddingClass={"p-0"}
            isLoading={isLoading}
            leftLabel="Vehicle fee (per vehicle)"
            leftText={perVehicle}
            rightLabel="Driver fee (per driver)"
            rightText={perDriver}
          />

          <OrganizationDetailsCardBlock
            isLoading={isLoading}
            leftLabel="Org. pays charging commission rate"
            leftText={organizationServiceRate}
            rightLabel="Driver pays charging commission rate"
            rightText={driverServiceRate}
          />
          <OrganizationDetailsCardBlock
            isLoading={isLoading}
            leftLabel="Org. pays charging discount rate"
            leftText={organizationDiscountRate}
            rightLabel="Driver pays charging discount rate"
            rightText={driverDiscountRate}
          />

          <OrganizationDetailsCardBlock
            isLoading={isLoading}
            leftLabel="Net payment terms"
            leftText={netPaymentTerms?.toString()??"-"}
            rightLabel="Invoice Cycle (Org. Charging Fee)"
            rightText={invoiceCycle}
          />
          <OrganizationDetailsCardBlock
            isLoading={isLoading}
            leftLabel="Purchase order (PO)"
            leftText={purchaseOrder??"-"}
            rightLabel="Driver charging late fee rate"
            rightText={chargingLateFeeRateForDriver}
            isBorderBottomVisible={true}
          />

          <OrganizationDetailsCardBlock
            isLoading={isLoading}
            rightTextOverwrite={<BlackButton onClick={handleOpenSettingsForm} text="Fee Settings" />}
            isBorderBottomVisible={false}
          />

        </Card>
      </Col>
    </>
  );
}

export default OrganizationDetailsFeeSettingsCard;
