import { ApiResponse } from "types/api.types";
import { AddManualInvoicePayload, AudienceType, Invoice } from "types/invoice.types";
import { generateApiEndpoint } from "utils";
import restApiClient from "./client";

/**
 * POST: '/board/invoice'
 */
export async function addManualInvoiceAsync(payload: AddManualInvoicePayload): Promise<ApiResponse<{}>> {
  const url = payload.audienceType === AudienceType.ORGANIZATION ? `organizations/${payload.audienceId}/invoices` : `organizations/${payload.organizationId}/users/${payload.audienceId}/invoices`;
  const endpoint = generateApiEndpoint(`board/${url}`);
  const response = await restApiClient.post(endpoint, {
    amount: payload.amount,
    description: payload.description,
  });
  return response.data;
}


/**
 * GET: 'board/invoices/{id}'
 */
export async function getInvoiceAsync(organizationId: string, invoiceId: string): Promise<ApiResponse<Invoice>> {
  const endpoint = generateApiEndpoint(`board/organizations/${organizationId}/invoices/${invoiceId}`);
  const response = await restApiClient.get(endpoint);

  return response.data;
}