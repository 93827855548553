import Box from "@mui/material/Box";
import * as React from "react";
import { StyledTab, StyledTabs } from "../tab/StyledTab";
import { useHistory, useParams } from "react-router";
import { useLocation } from "react-router-dom";


export const driverDetailsRoutes = {
  overview: {
    ix: 0,
    path: "overview",
    label: "Overview",
    isTeslaRelated: false
  },
  assignments: {
    ix: 1,
    path: "assignments",
    label: "Assignments",
    isTeslaRelated: false
  },
  reimbursements: {
    ix: 2,
    path: "reimbursements",
    label: "Reimbursements",
    isTeslaRelated: false
  }
};

type Props = {};

export default function DriverPageTab({}: Props) {
  const history = useHistory();
  const { driverId, organizationId } = useParams<{ driverId: string, organizationId: string }>();
  const { pathname } = useLocation();
  const routeValues = Object.values(driverDetailsRoutes);


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const route = routeValues.find(r => r.ix === newValue);

    history.replace(`/organizations/${organizationId}/drivers/${driverId}/${route?.path}`);
  };

  const currentPath = routeValues.find(r => pathname.includes(r.path));

  return (
    <Box className={"mb-4"}>
      <StyledTabs value={currentPath?.ix ?? 0} onChange={handleChange}>
        <StyledTab label="About" />
        <StyledTab label="Assigments" />
        <StyledTab label="Reimbursements" />
      </StyledTabs>
    </Box>
  );
}
