import { useMemo } from "react";

import Card from "../card/Card";
import Row from "../custom/Row";

import { getLocalFormattedDateForTables } from "utils/date.utils";
import {
  convertSecondsToMinutesAndHours,
  getChargerInfo,
  getChargerTypeIcon,
  getChargingPayStatDisplayContent,
  getChargingPayStatDisplayText
} from "../../utils/charging.utils";

import { BlackBodyText100, BlackBodyText300, BlackLinkText100 } from "../text/Text";
import { useStripeInvoiceUrlAccessor } from "../../hooks/invoice.hooks";
import ChargingStateBadge from "../badge/ChargingStateBadge";
import { Charging, ChargingProvider } from "../../types/charging.types";
import ChargingSessionsDetailsPageCardBlock from "./ChargingDetailsPageCardBlock";
import { getFormattedCostByProvider } from "../../utils/format.utils";

type Props = {
  charging: Charging
}

export default function ChargingDetailsPageSessionCard({ charging }: Props) {
  const {
    charger,
    provider,
    chargerName,
    cost,
    energyDelivered,
    createdAt: chargingDate,
    type,
    duration,
    state,
    paymentState,
    chargingInvoice
  } = charging;

  const { handleForwardToStripeAsync, isForwarding } = useStripeInvoiceUrlAccessor();

  const { label: chargerLabel, logo } = useMemo(() => getChargerInfo(provider as ChargingProvider), [provider]);

  const formattedDate = getLocalFormattedDateForTables(chargingDate);

  const formattedDuration = duration ? convertSecondsToMinutesAndHours(duration) : 0;

  const formattedCost = getFormattedCostByProvider(cost, provider);

  const chargerIcon = getChargerTypeIcon(type);


  return (
    <>
      <Card>
        <Row className="mb-1 align-center">
          <div className="h-48px  d-flex align-center mr-3">{logo}</div>
          <div>
            <BlackBodyText100 className={"m-0"}>{chargerName}</BlackBodyText100>
            <BlackBodyText300 className={"fs-16px"}>{chargerLabel}</BlackBodyText300>
          </div>
        </Row>

        <ChargingSessionsDetailsPageCardBlock
          leftLabel="Duration"
          leftAfterText={<ChargingStateBadge state={state} />}
          leftText={formattedDuration}
          rightLabel="Date"
          rightText={formattedDate}
        />
        {(cost || energyDelivered) && (
          <ChargingSessionsDetailsPageCardBlock
            leftLabel="Cost"
            leftText={formattedCost}
            rightLabel="kWh"
            rightText={energyDelivered}
          />
        )}
        <ChargingSessionsDetailsPageCardBlock
          leftTextOverwrite={
            <Row>
              {chargerIcon}
              <BlackBodyText100>{type}</BlackBodyText100>
            </Row>
          }
          leftLabel="Type" />
        <ChargingSessionsDetailsPageCardBlock
          leftLabel="PayStat"
          leftTextOverwrite={<p className={getChargingPayStatDisplayContent(paymentState).textClass}>
            {getChargingPayStatDisplayText(paymentState)}
          </p>}
          rightLabel="Invoice"
          rightTextOverwrite={chargingInvoice?.invoiceId ? (
            <BlackLinkText100 className={`${isForwarding && "cursor-progress"}`}
                              onClick={() => handleForwardToStripeAsync(charging.organizationId, chargingInvoice.invoiceId ?? "")}>
              See invoice
            </BlackLinkText100>
          ) : (
            "-"
          )}
        />


      </Card>
    </>
  );
}

/**
 *
 */
