import DriverDetailsSectionFormBox from "./DriverDetailsSectionFormBox";
import DriverDetailsSectionCard from "./DriverDetailsSectionCard";
import { Driver } from "types/driver.types";

import { getChargingLimit, getDriverPaymentResponsibilityLabelByPayerType } from "../../utils/driver.utils";


type Props = {
  isLoading: boolean;
  driver?: Driver;
};


export default function DriverDetailsPageChargingCard({ driver, isLoading }: Props) {
  const chargingLimit = driver?.limits?.chargingLimit;

  const chargingLimitDisplay = getChargingLimit(chargingLimit)?.label;

  return (
    <>

      <DriverDetailsSectionCard
        loadingCount={3}
        isLoading={isLoading}
        headerTitle={"Charging setup"}
        isEditButtonShowing
        content={
          <>
            <DriverDetailsSectionFormBox
              label={"Monthly charging credit"}
              value={chargingLimitDisplay}
            />

            <DriverDetailsSectionFormBox
              isBorderHidden
              label={"Payment Responsibility"}
              value={getDriverPaymentResponsibilityLabelByPayerType(driver?.payer)}
            />
          </>
        }
      />
    </>
  );
}
