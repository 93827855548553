import qs from "qs";
import { PaginatedApiResponse } from "types/api.types";
import { OrganizationDetails, OrganizationStatus } from "types/organization.types";
import { getFormattedNumberForUsdByCent, getPercentStringByRate } from "./format.utils";

export const getOrganizationStatusContent = (status) => {
  switch (status) {
    case OrganizationStatus.APPROVED:
      return { color: "#2BA318", label: "Approved" };
    case OrganizationStatus.REJECTED:
      return { color: "#C4003E", label: "Rejected" };
    case OrganizationStatus.PENDING_REVIEW:
      return { color: "#1E44FF", label: "Waiting" };
    default:
      return { color: "#2BA318", label: "Waiting" };
  }
};


export const getOrganizationFeeSettingsByOrganizationDetails = (organizationDetails?: OrganizationDetails) => {
  const organizationDetailsSettings = organizationDetails?.settings?.data;
  const platformFees = organizationDetailsSettings?.platformFees;
  const chargingDiscountRates = organizationDetailsSettings?.chargingDiscountRates;
  const chargingServiceRates = organizationDetailsSettings?.chargingServiceRates;
  const chargingLateFeeRates = organizationDetailsSettings?.chargingLateFeeRates;

  return {
    netPaymentTerms: organizationDetailsSettings?.invoice?.netPaymentTerms,
    purchaseOrder: organizationDetailsSettings?.invoice?.purchaseOrder ?? null,
    perVehicle: getFormattedNumberForUsdByCent(platformFees?.perVehicle),
    perDriver: getFormattedNumberForUsdByCent(platformFees?.perDriver),
    driverDiscountRate: getPercentStringByRate(chargingDiscountRates?.driver),
    organizationDiscountRate: getPercentStringByRate(chargingDiscountRates?.organization),
    driverServiceRate: getPercentStringByRate(chargingServiceRates?.driver),
    organizationServiceRate: getPercentStringByRate(chargingServiceRates?.organization),
    invoiceCycle: organizationDetailsSettings?.chargingBillingCycle,
    chargingLateFeeRateForDriver: getPercentStringByRate(chargingLateFeeRates?.driver),
    depositAmount: getFormattedNumberForUsdByCent(organizationDetailsSettings?.user.deposit.amount)
  };

};


export const getAllPaginatedListByLoopAsync = async <T>(fetchPaginatedList: (query: string) => Promise<PaginatedApiResponse<T>>) => {
  const list: T[] = [];
  let hasMore = true;
  let ix = 0;

  while (hasMore) {
    try {
      const paginationQueryString = qs.stringify({
        pagination: {
          page: ix,
          size: 50
        }
      }, { addQueryPrefix: true, skipNulls: true });
      const response = await fetchPaginatedList(paginationQueryString);
      list.push(...response.data);
      hasMore = response.meta.pagination.hasMore;
      ix++;
    } catch (error) {
      console.error(error);
      break;
    }
  }

  return list;
};