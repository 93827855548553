import Card from "components/card/Card";

import Col from "components/custom/Col";
import { OrganizationDetails } from "types/organization.types";
import OrganizationDetailsCardBlock from "./OrganizationDetailsCardBlock";
import OrganizationDetailsDepositEditor from "./form/OrganizationDetailsDepositEditor";
import HorizontalLine from "../line/HorizontalLine";
import OrganizationDetailsCardTitleBlock from "./OrganizationDetailsCardTitleBlock";

type Props = {
  readonly organizationDetails?: OrganizationDetails;
  readonly isLoading: boolean;
  readonly refetchOrganizationDetails: () => Promise<void>;
};

function OrganizationDetailsDepositSettingsCard({ organizationDetails, isLoading, refetchOrganizationDetails }: Props) {
  return (
    <Col className="h-235px  flex-column justify-between ">
      <Card>
        <OrganizationDetailsCardTitleBlock
          title={"Deposit settings"}
          description={"It displays the deposit amount the driver needs to pay. A value of 0 means no deposit is needed."}
        />
        <HorizontalLine />
        <OrganizationDetailsCardBlock
          paddingClass={"p-0"}
          isBorderBottomVisible={false}
          isLoading={isLoading}
          leftLabel="Deposit amount"
          leftTextOverwrite={
            <OrganizationDetailsDepositEditor
              organizationDetails={organizationDetails}
              refetchOrganizationDetails={refetchOrganizationDetails} />
          }
        />
      </Card>
    </Col>
  );
}

export default OrganizationDetailsDepositSettingsCard;
