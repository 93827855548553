import ClearFiltersButton from "components/button/ClearFiltersButton";
import Row from "components/custom/Row";
import { BlackBody2Text200 } from "components/text/Text";
import { useFilterUrlQuery } from "hooks/filter.hooks";
import React from "react";

type Props = {
  selectors: React.JSX.Element[];
  sortingSelector: React.JSX.Element;
};

function FilterMenu({ selectors, sortingSelector }: Props) {
  const { filterQuery,  handleResetFilter} = useFilterUrlQuery();
  const anyFiltersApplied = !!filterQuery;
  return (
    <Row className="align-items-center justify-between mb-2">
      <Row className="align-items-center">
        <BlackBody2Text200 className="mr-2">Filter by</BlackBody2Text200>
        {selectors}
        {anyFiltersApplied && <ClearFiltersButton onClick={handleResetFilter} />}
      </Row>
      {sortingSelector}
    </Row>
  );
}

export default FilterMenu;
