import React from "react";

import Col from "components/custom/Col";
import Row from "components/custom/Row";
import { getCategorizedSchedules } from "../../utils/vehicle-driver-schedule.utils";
import { BlackBody2Text200, BlackSubtitleText100 } from "../text/Text";
import { useVehicleDriverSchedulesAsync } from "../../hooks/query/vehicle-driver-schedule-query.hooks";
import DriverDetailsVehicleDriverScheduleList from "./DriverDetailsVehicleDriverScheduleList";
import OldVehicleDriverAssignmentsModal from "../vehicle-driver-schedule/OldVehicleDriverAssignmentsModal";

type Props = {}

function DriverDetailsAssignmentsContent({}: Props): JSX.Element {
  const vehicleDriverSchedulesHook = useVehicleDriverSchedulesAsync();

  const { schedules, isLoading: isSchedulesLoading } = vehicleDriverSchedulesHook;
  const { activeSchedules, plannedSchedules } = getCategorizedSchedules(schedules);


  return (
    <Row>
      <Col lg={6} className="pr-lg-3">

        <BlackSubtitleText100 className={"mb-2"}>Current schedule</BlackSubtitleText100>

        <DriverDetailsVehicleDriverScheduleList isLoading={isSchedulesLoading}

                                                schedules={activeSchedules} />

        <OldVehicleDriverAssignmentsModal isForDriver={true} />

      </Col>
      <Col lg={6} className="pl-lg-3">

        <BlackSubtitleText100 className={"mt-4"}>Scheduled assignments</BlackSubtitleText100>
        <BlackBody2Text200 className={"mb-2"}>The driver will be automatically assigned to the vehicle on the start
          date.</BlackBody2Text200>

        <DriverDetailsVehicleDriverScheduleList isLoading={isSchedulesLoading}
                                                schedules={plannedSchedules} />

      </Col>
    </Row>
  );
}

export default DriverDetailsAssignmentsContent;