import React from "react";

import Head from "layout/head/Head";
import Content from "layout/content/Content";
import OrganizationDriverChargingListTable from "components/organization/driver/OrganizationDriverChargingListTable";
import DriverPageTab from "../../../components/driver/DriverPageTab";
import DriverDetailsAssignmentsContent from "../../../components/driver/DriverDetailsAssignmentsContent";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import DriverDetailsReimbursementContent from "../../../components/driver/DriverDetailsReimbursementContent";
import { useOrganizationDriverAsync } from "../../../hooks/query/organization-query.hooks";
import DriverDetailsAboutSection from "../../../components/driver/DriverDetailsAboutContent";
import Row from "components/custom/Row";
import LoadingSkeleton from "../../../components/loading/LoadingSkeleton";
import { BlackBody2Text100, BlackBody2Text200, BlackH5Text100 } from "../../../components/text/Text";
import Col from "../../../components/custom/Col";
import { getDriverFullName, getDriverNameInitials } from "../../../utils/driver.utils";
import { getLocalFormattedDateAsDateMed } from "utils/date.utils";

const OrganizationDriverDetailsPage = () => {
  const { path } = useRouteMatch();
  const driverHooks = useOrganizationDriverAsync();
  const { isLoading, driver } = driverHooks;

  return (
    <React.Fragment>
      <Head title={"Drivers"} />

      <Content>
        <Row className="flex-wrap align-center justify-between mb-4">
          {isLoading ? (
            <div className="w-100">
              <LoadingSkeleton count={2} />
            </div>
          ) : (
            <Row className="center mb-2 mb-md-0">
              <div className="driver-details-avatar-badge">{getDriverNameInitials(driver)}</div>
              <Col className="ml-2">
                <BlackH5Text100>{getDriverFullName(driver)}</BlackH5Text100>
                <Row className="align-items-center mt-1">
                  <BlackBody2Text200 className="mr-1">Added:</BlackBody2Text200>
                  <BlackBody2Text100>{getLocalFormattedDateAsDateMed(driver?.createdAt)}</BlackBody2Text100>
                </Row>
              </Col>
            </Row>
          )}

        </Row>

        <DriverPageTab />

        <Switch>
          <Route exact path={`${path}/reimbursements`}
                 render={() =>
                   <DriverDetailsReimbursementContent driverHooks={driverHooks} />
                 }
          />

          <Route exact path={`${path}/assignments`}
                 render={() =>
                   <DriverDetailsAssignmentsContent />
                 }
          />

          <Route path={`${path}`}
                 render={() => <>   <DriverDetailsAboutSection driverHooks={driverHooks} />
                   <OrganizationDriverChargingListTable /> </>}
          />
        </Switch>
      </Content>
    </React.Fragment>
  );
};

export default OrganizationDriverDetailsPage;
