import { Period } from "./filter.types";
import { PayerType } from "./payment.types";
import { Vehicle } from "./vehicle.types";
import { useOrganizationDriverAsync } from "../hooks/query/organization-query.hooks";
import { PolygonLocation } from "./location.types";
import { FleetGroup } from "./fleet-group.types";

export enum Country {
  US = "US",
  CANADA = "CA"
}


export type Address = {
  street?: string;
  street2?: string;
  city: string;
  state: string;
  postalCode: string;
  country: Country;
};

export interface DriverSettingsPayload {
  chargingReimbursement: {
    enabled: boolean;
    tariff: {
      fixed: {
        // Rates are in cents
        energyPrice: number
        taxRate: number;
      };
    } | null
  };
}


export type DriverSettings = {
  id: string;
  data: DriverSettingsPayload;
  createdAt: string;
  updatedAt: string;
};


export type Driver = {
  id: string;
  organizationId: string;
  type: string;
  email: string;
  phone_number: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  active: boolean;
  payer: PayerType;
  jobTitle: string;
  createdAt: string;
  updatedAt: string;
  activatedAt: string;
  expiredAt: string;
  limits: Limits;
  address: Address;
  departments: Department[];
  chargingReimbursementLocations: PolygonLocation[]
  vehicles: Vehicle[];
  settings: DriverSettings;
  hasDefaultPaymentMethod: boolean
  groups: FleetGroup[]
  deposit: {
    depositedAmount: number
    liabilityAmount: number
  }
}

export type Department = {
  id: string;
  name: string;
}

export type Limits = {
  id: string;
  chargingLimit: number;
}

export enum UserStatus {
  Active = "Active",
  Inactive = "Inactive"
}


export enum UserType {
  FIXED = "fixed",
  TEMPORARY = "temporary",
}


export type DriverTableFilters = {
  active?: {
    $in?: boolean[] | string[]
  },
  type?: {
    $eq: keyof typeof UserType
  },
  payer?: {
    $in: PayerType[]
  },
  createdAt?: {
    $within: Period,
    $gte: string,
    $lt: string
  },
  q?: {
    $eq: string
  }
}


export type DriverHooks = ReturnType<typeof useOrganizationDriverAsync>