import { ApiResponse } from "types/api.types";
import { Charging } from "types/charging.types";
import { Driver } from "types/driver.types";
import { Invoice } from "types/invoice.types";
import { OrganizationDetails, OrganizationList, OrganizationSettings } from "types/organization.types";
import { Vehicle } from "types/vehicle.types";
import { generateApiEndpoint } from "../utils";
import { getPaginatedApiWithQueryAsync } from "./api.services";
import restApiClient from "./client";


/**
 * GET: '/api/organizations'
 */
export async function getOrganizationsByStatusAsync(status: string): Promise<ApiResponse<OrganizationList>> {
  const endpoint = generateApiEndpoint(`board/organizations?status=${status}`);
  const response = await restApiClient.get(endpoint);
  return response.data;
}

/**
 * GET: '/api/organizations/${customerId}/charging'
 */

export async function getOrganizationChargingListAsync(organizationId: string, query: string = "") {
  const serviceName = `board/organizations/${organizationId}/charging`;
  const endpoint = generateApiEndpoint(serviceName);
  return getPaginatedApiWithQueryAsync<Charging>(endpoint, query);
}


/**
 * GET: '/api/organizations/${orgId}'
 */
export async function getOrganizationAsync(organizationId: string): Promise<ApiResponse<OrganizationDetails>> {
  const endpoint = generateApiEndpoint(`board/organizations/${organizationId}`);
  const response = await restApiClient.get(endpoint);
  return response.data;
}

/**
 * GET: '/api/organizations/${orgId}/charging/chargingId'
 */
export async function getOrganizationChargingAsync(organizationId: string, chargingId: string): Promise<ApiResponse<Charging>> {
  const endpoint = generateApiEndpoint(`board/organizations/${organizationId}/charging/${chargingId}`);
  const response = await restApiClient.get(endpoint);
  return response.data;
}

/**
 * GET: '/api/organizations/${orgId}/users/driverId'
 */
export async function getOrganizationDriverAsync(organizationId: string, driverId: string): Promise<ApiResponse<Driver>> {
  const endpoint = generateApiEndpoint(`board/organizations/${organizationId}/users/${driverId}`);
  const response = await restApiClient.get(endpoint);
  return response.data;
}

/**
 * GET: '/api/organizations/${orgId}/vehicles/vehicleId'
 */
export async function getOrganizationVehicleAsync(organizationId: string, vehicleId: string): Promise<ApiResponse<Vehicle>> {
  const endpoint = generateApiEndpoint(`board/organizations/${organizationId}/vehicles/${vehicleId}`);
  const response = await restApiClient.get(endpoint);
  return response.data;
}

/**
 * PATCH: '/api/organizations/${customerId}'
 */
export async function updateOrganizationAsync(organizationId: string, data: OrganizationSettings) {
  const endpoint = generateApiEndpoint(`board/organizations/${organizationId}`);
  await restApiClient.patch(endpoint, data);
}

/**
 * GET: '/api/organizations'
 */
export async function updateApplication(id, status) {
  const endpoint = generateApiEndpoint(`board/organizations/${id}/${status}`);
  const response = await restApiClient.post(endpoint);
  return response.data;
}


/**
 * GET: board/organizations/id/charging/export
 */

export async function exportOrganizationChargingListAsync(organizationId: string, query: string = "") {
  const serviceName = `board/organizations/${organizationId}/charging/export` + query;
  const endpoint = generateApiEndpoint(serviceName);
  return restApiClient.get<string>(endpoint, {
    headers: {
      Accept: "text/csv, application/json, text/plain, */*"
    },
    responseType: "blob"
  });
}


/**
 * GET: '/api/organizations/orgId/users'
 */
export async function getOrganizationDriversAsync(organizationId: string, query: string = "") {
  const serviceName = `board/organizations/${organizationId}/users`;
  const endpoint = generateApiEndpoint(serviceName);
  return getPaginatedApiWithQueryAsync<Driver>(endpoint, query);
}



/**
 * GET: '/api/organizations/orgId/users/driverId/charging'
 */

export async function getOrganizationDriverChargingListAsync(organizationId: string, driverId: string, query: string = "") {
  const serviceName = `board/organizations/${organizationId}/users/${driverId}/charging`;
  const endpoint = generateApiEndpoint(serviceName);
  return getPaginatedApiWithQueryAsync<Charging>(endpoint, query);
}


export async function getOrganizationVehiclesAsync(organizationId: string, query: string = "") {
  const serviceName = `board/organizations/${organizationId}/vehicles`;
  const endpoint = generateApiEndpoint(serviceName);
  return getPaginatedApiWithQueryAsync<Vehicle>(endpoint, query);
}


export async function getOrganizationVehicleChargingListAsync(organizationId: string, vehicleId: string, query: string = "") {
  const serviceName = `board/organizations/${organizationId}/vehicles/${vehicleId}/charging`;
  const endpoint = generateApiEndpoint(serviceName);
  return getPaginatedApiWithQueryAsync<Charging>(endpoint, query);
}



/**
 * GET: 'board/organizations/{id}/invoices'
 */
export async function getOrganizationInvoicesAsync(organizationId: string, query: string = "") {
  const endpoint = generateApiEndpoint(`board/organizations/${organizationId}/invoices`);
  return getPaginatedApiWithQueryAsync<Invoice>(endpoint, query);
}


/**
 * DELETE: '/api/organizations/${id}/vehicles/${id}'
 */
export async function deleteOrganizationVehicleAsync(organizationId: string, vehicleId: string) {
  const endpoint = generateApiEndpoint(`board/organizations/${organizationId}/vehicles/${vehicleId}`);
  await restApiClient.delete(endpoint);
}

