import LoadingSkeleton from "components/loading/LoadingSkeleton";
import { BlackSubtitleText100 } from "components/text/Text";
import React from "react";
import Row from "../custom/Row";
import Card from "../card/Card";

type Props = {
  isLoading: boolean;
  isEditButtonShowing?: boolean;
  icon?: React.JSX.Element;
  headerTitle?: string;
  content?: React.JSX.Element;
  onEditButtonPressed?: () => void;
  loadingCount?: number;
  headerButton?: JSX.Element;
  contentClassName?: string;
};

export default function DriverDetailsSectionCard({
                                                   isLoading,
                                                   icon,
                                                   headerTitle,
                                                   content,
                                                   isEditButtonShowing,
                                                   onEditButtonPressed,
                                                   loadingCount,
                                                   headerButton,
                                                   contentClassName = "mt-4"
                                                 }: Props) {
  return (
    <Card padding="p-2 p-md-4">
      {isLoading ? (
        <LoadingSkeleton count={loadingCount ?? 5} height={40} />
      ) : (
        <>
          <Row className="center  justify-between flex-nowrap">
            <Row className="align-items-center justify-content-start flex-nowrap">
              {icon && <div className="mr-2">{icon}</div>}
              <BlackSubtitleText100>{headerTitle}</BlackSubtitleText100>
            </Row>

            {headerButton}
          </Row>
          <div className={contentClassName}>{content}</div>
        </>
      )}
    </Card>
  );
}
